<ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app>
<div class="abc"></div>
<div class="loaderClassforloader" *ngIf="loader.loading">
  <div class="row">
    <div class="col-md-12">
      <div class="lottieWrapper">
        <!-- <ion-spinner></ion-spinner> -->
        <img class="loader-img" src="../assets/images/loader.gif">
      </div>
    </div>
  </div>
</div>

