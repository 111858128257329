import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/core/constant';
import { LoaderService } from '../loader/loader.service';
import { ToastComponent } from 'src/app/component/toast/toast.component';
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  basepath: string = AppSettings.API_ENDPOINT;
  headers: any
  constructor(private http: HttpClient, private router: Router,
    private loader: LoaderService,
    private toast: ToastComponent
  ) {
    this.headers = new Headers();
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept', 'application/json');
    this.headers.append('content-type', 'application/json');

  }
  canActivate(): any {

    if (localStorage.getItem("token")) {
      this.router.navigate(['/home-page']);
      return false;

    }
    else {
      return true;
    }
  }
  AddUser(data: any) {
    this.loader.showLoading();

    let addRatingURL = this.basepath + 'signup';
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: this.headers })
        .subscribe(res => {
          this.loader.hideLoading();

          resolve(res);
        }, (err) => {
          this.loader.hideLoading();
          reject(err);
        });
    });
  }

  Sendotp(data: any) {
    this.loader.showLoading();

    let addRatingURL = this.basepath + 'verifyotp';
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: this.headers })
        .subscribe(res => {
          this.loader.hideLoading();

          resolve(res);
        }, (err) => {
          this.loader.hideLoading();

          this.toast.presentToast('Please Provide Valid Otp');
          reject(err);
        });
    });

  }
  resendOtp(data: any,model:any) {
    this.loader.showLoading();

    let addRatingURL = this.basepath + model;
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: this.headers })
        .subscribe(res => {
          this.loader.hideLoading();

          resolve(res);
        }, (err) => {
          this.loader.hideLoading();

          this.toast.presentToast('Please Provide Valid Otp');
          reject(err);
        });
    });

  }
  login(data: any) {
    this.loader.showLoading();

    let addRatingURL = this.basepath + 'sendotp';
    return new Promise((resolve, reject) => {

      this.http.post(addRatingURL, data, { headers: this.headers })
        .subscribe(res => {
          this.loader.hideLoading();

          resolve(res);
        }, (err) => {
          this.loader.hideLoading();
          reject(err);
        });
    });
  }

  getById(id: any) {
    this.loader.showLoading();

    let addRatingURL = this.basepath + 'user/' + id;
    return new Promise((resolve, reject) => {

      this.http.get(addRatingURL, { headers: this.headers })
        .subscribe(res => {
          this.loader.hideLoading();


          resolve(res);
        }, (err) => {
          this.loader.hideLoading();

          // this.toast.presentToastError(AppSettings.GENERIC_ERROR);
          reject(err);
        });
    });


  }

  addAddress(data: any, id: any) {
    this.loader.showLoading();

    let addRatingURL = this.basepath  + 'user/' + id;
    return new Promise((resolve, reject) => {

      this.http.patch(addRatingURL, data, { headers: this.headers })
        .subscribe(res => {
          this.loader.hideLoading();


          resolve(res);
        }, (err) => {
          this.loader.hideLoading();

          reject(err);
        });
    });
  }
}
