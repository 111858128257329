import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { LoaderService } from './services/loader/loader.service';
import { io, Socket } from 'socket.io-client';
import { ModalController } from '@ionic/angular';
import { SplashComponent } from './splash/splash.component';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userData: any = localStorage.getItem('userData');


  loadingFlag: boolean = false;
  lottieConfig: any;
  anim: any;
  socket: Socket;
  // SOC_URL = 'http://localhost:4003';
  SOC_URL = 'https://api.vid-food.stageprojects.xyz';

  constructor(public loader: LoaderService,
    private modalController:  ModalController) {
      this.userData = JSON.parse(this.userData);

      this.presentSplash();

    this.socket = io(this.SOC_URL, {
      // @ts-ignore
      transports: ['polling'],
      upgrade: false,
      reconnectionAttempts: 10,
      reconnectionDelayMax: 10000,
      reconnectionDelay: 5000,
      closeOnBeforeunload: false,
    });
    console.log(this.socket, 'socket');
    this.socket.on('connect', () => {
      this.socket.emit('join',this.userData?._id)
      console.log('Connected to server',this.userData?._id);
    });
    setTimeout(() => {
      this.loadingFlag = true;
    }, 2000);
    this.lottieConfig = {
      path: 'assets/loading.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true,
    };

  }

  async presentSplash() {

    const modal = await this.modalController.create({
      component: SplashComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
}

  animationCreated(anim: any) {
    this.anim = anim;
  }
  sendMessage(message:any) {
    this.socket.emit('orderCreate',message);
  }
  get(message:any,data:any) {
    this.socket.on(message,data);
  }
  orderCancel(message:any) {
    console.log('cancel')
    this.socket.emit('orderCancelAdmin',message);
  }
}
